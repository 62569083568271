export const BreakpointMobileName = "mobile";
export const BreakpointTabletName = "tablet";
export const BreakpointDesktopName = "desktop";
export const BreakpointDesktopBigName = "desktop-big";

export type BreakpointName =
  | typeof BreakpointDesktopName
  | typeof BreakpointMobileName
  | typeof BreakpointTabletName
  | typeof BreakpointDesktopBigName;

export type Breakpoints = {
  [breakpoint in BreakpointName]: number;
};

export const breakpoints: Breakpoints = {
  mobile: 375,
  tablet: 768,
  desktop: 1240,
  "desktop-big": 1536,
};

export type FormFactor = keyof Breakpoints;
